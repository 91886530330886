class VideoAutoplay extends HTMLElement {
  muted = true;
  playing = true;

  connectedCallback() {
    this.$toggleAudio = this.querySelector("[data-video-autoplay-toggle]");
    this.$btnLabel = this.$toggleAudio.querySelector(
      '[data-module-bind="btn-label"]',
    );
    this.$video = this.querySelector("video");

    this.$toggleAudio.addEventListener("click", () => {
      if (this.muted) {
        this.$btnLabel.textContent = this.$toggleAudio.dataset.labelMute;
        this.$video.muted = false;
        this.muted = false;
      } else {
        this.$btnLabel.textContent = this.$toggleAudio.dataset.labelUnmute;
        this.$video.muted = true;
        this.muted = true;
      }
    });

    this.$video.addEventListener("click", () => {
      if (this.playing) {
        this.$video.pause();
        this.playing = false;
      } else {
        this.$video.play();
        this.playing = true;
      }
    });
  }
}

window.customElements.define("video-autoplay", VideoAutoplay);
