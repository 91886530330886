class PopoverMenu extends HTMLElement {
  constructor() {
    super();
    this.hideFoldoutOnBlur = this.hideFoldoutOnBlur.bind(this);
  }

  get open() {
    return this.hasAttribute("open");
  }

  set open(value) {
    if (value) {
      this.setAttribute("open", "");
    } else {
      this.removeAttribute("open");
    }
  }

  static observedAttributes = ["open"];

  connectedCallback() {
    this.$toggle = this.querySelector("[data-toggle-popover]");
    this.$menu = this.querySelector("[data-popover]");
    this.$toggle.setAttribute("aria-expanded", "false");

    this.$toggle.addEventListener("click", () => {
      this.open = !this.open;
    });
  }

  attributeChangedCallback(name) {
    if (name === "open") {
      if (this.open) {
        this.openPopover();
      } else {
        this.closePopover();
      }
    }
  }

  openPopover() {
    this.$toggle.setAttribute("aria-expanded", "true");
    this.$menu.hidden = false;

    requestAnimationFrame(() => {
      document.documentElement.addEventListener(
        "click",
        this.hideFoldoutOnBlur,
      );
    });
  }

  closePopover() {
    this.$toggle.setAttribute("aria-expanded", "false");
    this.$menu.hidden = true;
    document.documentElement.removeEventListener(
      "click",
      this.hideFoldoutOnBlur,
    );
  }

  hideFoldoutOnBlur(e) {
    if (!this.contains(e.target)) {
      this.open = false;
    }
  }
}

window.customElements.define("popover-menu", PopoverMenu);
