class CardSlider extends HTMLElement {
  async connectedCallback() {
    const Swiper = (await import("swiper")).default;
    const { Navigation } = await import("swiper/modules");
    this.$slider = this.querySelector("[data-module-bind=slider]");
    this.$controls = this.querySelector("[data-card-slider-controls]");
    const $btnPrev = this.querySelector("[data-button-prev]");
    const $btnNext = this.querySelector("[data-button-next]");

    this.swiper = new Swiper(this.$slider, {
      modules: [Navigation],
      slidesPerView: "auto",
      cssMode: true,
      spaceBetween: 24,
      navigation: {
        nextEl: $btnNext,
        prevEl: $btnPrev,
      },
    });

    if (!this.swiper.isLocked) {
      this.$controls.hidden = false;
    }

    /*this.swiper.on("fromEdge", (swiper, progress) => {
      // this.$progress.setAttribute("value", progress.toString());
      this.$slider.classList.remove("is-at-end", "is-at-start");
    });*/

    this.swiper.on("lock", () => {
      this.$controls.hidden = true;
    });

    this.swiper.on("unlock", () => {
      this.$controls.hidden = false;
    });

    /*this.swiper.on("reachEnd", () => {
      this.$slider.classList.add("is-at-end");
    });

    this.swiper.on("reachBeginning", () => {
      this.$slider.classList.add("is-at-start");
    });*/
  }
}

customElements.define("card-slider", CardSlider);
