class TombstoneOverview extends HTMLElement {
  static observedAttributes = ["total", "currenttotal"];

  get culture() {
    return this.getAttribute("culture");
  }

  get currentpageid() {
    return this.getAttribute("currentpageid");
  }

  get total() {
    return Number(this.getAttribute("total"));
  }

  set total(value) {
    this.setAttribute("total", value);
  }

  get currentTotal() {
    return Number(this.getAttribute("currenttotal"));
  }

  set currentTotal(value) {
    this.setAttribute("currenttotal", value);
  }

  get result() {
    return this.getAttribute("result");
  }

  get results() {
    return this.getAttribute("results");
  }

  constructor() {
    super();
    this.handleChange = this.handleChange.bind(this);
    this.handleClickActiveFilters = this.handleClickActiveFilters.bind(this);
    this.handleClickLoadMore = this.handleClickLoadMore.bind(this);
    this.updateItemList = this.updateItemList.bind(this);
    this.checkScreenSize = this.checkScreenSize.bind(this);
    this.items = [];
  }

  connectedCallback() {
    this.$form = this.querySelector("[data-tombstone-overview-form]");
    this.api = this.$form.getAttribute("action");
    this.$amount = this.querySelector("[data-tombstone-overview-amount]");
    this.$amountText = this.querySelector(
      "[data-tombstone-overview-amount-text]",
    );
    this.$list = this.querySelector("[data-tombstone-overview-list]");
    this.$filterFoldout = this.querySelector("[data-filters-foldout]");
    this.$showMoreBtn = this.querySelector("[data-load-more]");

    this.$activeFilterList = this.querySelector(
      "[data-tombstone-overview-active-filters]",
    );
    this.$TransactionItemTemplate = this.querySelector(
      "#template-card-transaction",
    );
    this.$StoryItemTemplate = this.querySelector("#template-card-story");
    this.$activeFilterItemTemplate = this.querySelector(
      "#template-active-filter",
    );
    this.$form.addEventListener("change", this.handleChange);

    this.$activeFilterList.addEventListener(
      "click",
      this.handleClickActiveFilters,
    );

    this.checkScreenSize();

    this.$showMoreBtn.addEventListener("click", this.handleClickLoadMore);
  }

  checkScreenSize() {
    const mql = window.matchMedia("(min-width: 48rem)");

    if (mql.matches) {
      this.$filterFoldout.open = true;
    }
  }

  handleClickActiveFilters(e) {
    // use event delegation, check for click on a button
    const $btn = e.target.closest("button");
    // return if no button was clicked
    if (!$btn) return;
    this.$form.querySelector(`#${$btn.getAttribute("aria-controls")}`).checked =
      false;
    this.handleChange();
  }

  handleChange() {
    this.formData = new FormData(this.$form);
    this.updateUrl(this.formData);
    this.updateActiveFilters();
    this.getResults(this.formData);
  }

  async getResults(formData) {
    formData.append("Culture", this.culture);
    formData.append("CurrentPageId", this.currentpageid);
    formData.append("CurrentTotal", 0);

    const req = await fetch(
      `${this.api}?${new URLSearchParams(formData).toString()}`,
    );

    const res = await req.json();
    this.total = res.Results;
    this.items = res.Items;
    this.$list.innerHTML = "";
    this.updateItemList();
    this.currentTotal = res.Items.length;
  }

  handleClickLoadMore() {
    this.formData = new FormData(this.$form);
    this.formData.append("Culture", this.culture);
    this.formData.append("CurrentPageId", this.currentpageid);
    this.formData.append("CurrentTotal", this.currentTotal);
    this.updateUrl(this.formData);
    this.loadMore(this.formData);
  }

  async loadMore(formData) {
    const req = await fetch(
      `${this.api}?${new URLSearchParams(formData).toString()}`,
    );

    const res = await req.json();
    this.items = res.Items;
    this.currentTotal += res.Items.length;
    this.updateItemList();
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (name === "total") {
      if (this.$amount) {
        this.$amount.innerHTML = newValue;
      }

      if (this.$amountText) {
        this.$amountText.innerHTML = newValue > 1 ? this.results : this.result;
      }
    }

    if (name === "currenttotal") {
      this.updateShowLoadMoreBtn();
    }
  }

  updateItemList() {
    this.items.forEach((item) => {
      if (item.CardType === "Transaction") {
        const $item = this.$TransactionItemTemplate.content.cloneNode(true);
        $item.querySelector("[data-title]").textContent = item.title;
        $item
          .querySelector("[data-transaction-link]")
          ?.setAttribute("href", item?.url);
        $item.querySelector("[data-transaction-type]").textContent =
          item.transactionType;
        $item.querySelector("[data-business-type]").textContent =
          item.businessType;

        if (item.logo1?.url) {
          $item
            .querySelector("[data-image-1] img")
            .setAttribute("srcset", item.logo1.url);
          $item
            .querySelector("[data-image-1] img")
            .setAttribute("alt", item.logo1?.alt || "");
        }
        if (item.logo2?.url) {
          $item
            .querySelector("[data-image-2] img")
            .setAttribute("srcset", item.logo2.url);
          $item
            .querySelector("[data-image-2] img")
            .setAttribute("alt", item.logo2?.alt || "");
        } else {
          $item.querySelector("[data-image-2] picture").remove();
        }

        this.$list.appendChild($item);
      } else if (item.CardType === "ClientStoryHighlight") {
        const $item = this.$StoryItemTemplate.content.cloneNode(true);

        $item
          .querySelector("[data-story-link]")
          ?.setAttribute("href", item?.url);
        $item.querySelector("[data-title]").textContent = item.title;
        $item.querySelector("[data-name]").textContent = item.clientName;
        $item.querySelector("[data-company]").textContent = item.companyName;

        if (item.image.url) {
          const $img = $item.querySelector("[data-avatar-1] img");
          $img.setAttribute(
            "srcset",
            `${item.image.url} 1x, ${
              item.image.urlDpr2 ? `${item.image.urlDpr2} 2x` : ""
            }`,
          );
          $img.setAttribute("alt", item.image.alt);
          $item.querySelector("[data-avatar-2]").remove();
        }

        this.$list.appendChild($item);
      }
    });
  }

  updateActiveFilters() {
    const $activeFilters = this.$form.querySelectorAll(":checked");
    this.$activeFilterList.textContent = "";

    $activeFilters.forEach(($activeFilter) => {
      const item = this.$activeFilterItemTemplate.content.cloneNode(true);
      // set aria-controls attribute
      item
        .querySelector("button")
        .setAttribute("aria-controls", $activeFilter.id);
      // set label
      item.querySelector("[data-label]").textContent =
        $activeFilter.dataset.label;
      this.$activeFilterList.appendChild(item);
    });
  }

  updateUrl(formData) {
    window.history.replaceState(
      null,
      "",
      `?${new URLSearchParams(formData).toString()}`,
    );

    return this;
  }

  updateShowLoadMoreBtn() {
    if (this.total > this.currentTotal) {
      if (this.$showMoreBtn.hasAttribute("hidden")) {
        this.$showMoreBtn.removeAttribute("hidden");
      }
      return;
    }
    this.$showMoreBtn.setAttribute("hidden", true);
  }
}

window.customElements.define("tombstone-overview", TombstoneOverview);
