class GoogleMap extends HTMLElement {
  get iconPath() {
    return this.getAttribute("iconpath");
  }

  async connectedCallback() {
    const { Loader } = await import("@googlemaps/js-api-loader");
    // this.iconPath = "/images/";
    this.offices = window.mapOptions.offices;
    this.midcapOffices = window.mapOptions.midcapOffices;
    this.loader = new Loader({
      apiKey: window.mapOptions.apiKey,
      version: "weekly",
      // libraries: ["places"],
    });

    this.stylesArray = [
      {
        featureType: "administrative",
        elementType: "labels.text",
        stylers: [
          {
            color: "#88bbce",
          },
          {
            weight: 3,
          },
        ],
      },
      {
        featureType: "administrative",
        elementType: "labels.text.stroke",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "administrative.country",
        elementType: "geometry.stroke",
        stylers: [
          {
            color: "#6b6b6b",
          },
        ],
      },
      {
        featureType: "administrative.land_parcel",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "administrative.neighborhood",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "administrative.province",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "landscape",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#f2f8f5",
          },
        ],
      },
      {
        featureType: "landscape.man_made",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#ebebeb",
          },
        ],
      },
      {
        featureType: "poi",
        stylers: [
          {
            color: "#bfe8c4",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "labels.text",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "labels.icon",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "road.arterial",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "road.highway",
        stylers: [
          {
            color: "#e6e6e6",
          },
        ],
      },
      {
        featureType: "road.local",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "transit",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#e2f6fd",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "labels.text",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
    ];

    this.loader.load().then(async () => {
      const { Map } = await google.maps.importLibrary("maps");

      this.styledMapType = new google.maps.StyledMapType(this.stylesArray);

      this.map = new Map(this, {
        zoom: 8,
        center: { lat: 52.18958, lng: 5.29524 },
        mapId: "DEMO_MAP_ID",
        mapTypeId: google.maps.MapTypeId.TERRAIN,
        // styles: this.stylesArray,
      });

      this.map.mapTypes.set("styled_map", this.styledMapType);
      this.map.setMapTypeId("styled_map");

      this.offices.forEach((office) => {
        this.createMarker(office, "rma");
      });

      this.midcapOffices.forEach((office) => {
        this.createMarker(office, "advior");
      });
    });
  }

  async createMarker(office, markerType) {
    const img = document.createElement("img");
    img.src = `${this.iconPath}${
      markerType === "rma" ? "Pointer_Rembrandt.svg" : "Pointer_Advior.svg"
    }`;

    const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");
    const marker = new AdvancedMarkerElement({
      map: this.map,
      position: { lat: office.lat, lng: office.long },
      title: office.title,
      content: img,
    });

    const mapsUrl = `https://maps.google.com/maps/search/?api=1&query_place_id=${office.google_place_id}&query=${office.lat},${office.long}`,
      infowindow = new google.maps.InfoWindow({
        content: `
                    <div id="content">
                        <div id="siteNotice">
                        </div>
                        <h5>${office.title}</h5>
                        <a class="c-link c-link-bare" href="${mapsUrl}" target="_blank">Tonen in Google Maps</a>
                    </div>
                `,
      });

    marker.addListener("click", () => {
      infowindow.open({
        anchor: marker,
        map: this.map,
        shoudFocus: false,
      });
    });
  }
}

window.customElements.define("google-map", GoogleMap);
